.react-tel-input {
  .form-control {
    width: 100%;
    padding: 0.6rem 0.375rem 0.6rem 3.75rem;
    min-height: 2.5rem;
    &:focus {
      border-color: $theme-color-primary;
      box-shadow: none;
    }
  }
}

.image-chooser {
  cursor: pointer;
  &:hover {
    background: white;
  }
}
